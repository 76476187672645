import { Component, OnInit, Input } from '@angular/core';
import { Activity } from '../activity-service.service';

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.less']
})
export class ActivityTableComponent implements OnInit {

  @Input()
  activities: Activity[]
  constructor() {
    this.activities = [];
  }

  ngOnInit() {
  }

}
