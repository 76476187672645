import { Component, OnInit } from '@angular/core';
import { Group, GroupServiceService } from '../group-service.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less']
})
export class HomePageComponent implements OnInit {

  groups: Group[];

  constructor(private groupService: GroupServiceService) {
    this.groups = [];
  }

  async ngOnInit() {
    this.groups = await this.groupService.getAllGroups();
  }

}
