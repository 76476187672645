import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactServiceService {

  private data: Promise<ContactType[]>;
  constructor(private http: HttpClient) {
    this.data = new Promise((resolve, reject) => {
      this.http.get('assets/contacts.json?t' + new Date().getTime())
        .subscribe((data: ContactType[]) => resolve(data));
    })
  }


  async getContacts(group: string): Promise<Contact[]> {
    return (await this.data)
      .map(x => new Contact(x))
      .filter(x => x.group == group);
  }

  async getPrimaryContact(group: string): Promise<Contact> {
    return (await this.getContacts(group))
      .filter(x => x.isPrimary)[0];

  }
}

export type ContactType = {
  name: string,
  primary: boolean,
  email: string,
  phone: string,
  group: string
};

export class Contact {
  constructor(private contact: ContactType) {

  }

  get group(): string {
    return this.contact.group;
  }

  get phone(): string {
    return this.contact.phone;
  }

  get name(): string {
    return this.contact.name;
  }

  get isPrimary(): boolean {
    return this.contact.primary;
  }

  get email(): string {
    return this.contact.email;
  }
}