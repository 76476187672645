import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Activity, ActivityServiceService } from './activity-service.service';
import { Contact, ContactServiceService } from './contact-service.service';

@Injectable({
  providedIn: 'root'
})
export class GroupServiceService {

  private data: Promise<GroupType[]>;

  constructor(private http: HttpClient, 
    private contactService: ContactServiceService, 
    private activityService: ActivityServiceService
    ) {
    this.data = new Promise((resolve, reject) => {
      this.http.get('assets/groups.json?t' + new Date().getTime())
        .subscribe((groups: GroupType[]) => resolve(groups));
    });
  }

  async getGroup(group: string): Promise<Group> {
    let data = await this.data;
    for(let grp of data) {
      if(grp.reference == group) {
        return new Group(grp, await this.contactService.getPrimaryContact(grp.reference), await this.activityService.getNextActivity(grp.reference))

      }
    }
  }

  async getAllGroups() : Promise<Group[]> {
    let data = await this.data;
    let result = [];
    for(let grp of data) {

        result.push(new Group(grp, await this.contactService.getPrimaryContact(grp.reference), await this.activityService.getNextActivity(grp.reference)))
    }

    return result;
  }
  
}

export type GroupType = {
  reference: string,
  displayName: string,
  description: string[],
  audience: string,
  cadence: {
    day: string,
    startTime: string,
    endTime: string
  },
  location: {
    name: string,
    street: string,
    zipCode: string,
    city: string,
    notes: string[]
  },
  costs: string,
  closedReason?: string
}

export class Group {
  constructor(private groupType: GroupType, public contact: Contact, public nextActivity: Activity) {

  }

  get audience(): string {
    return this.groupType.audience;
  }

  get reference() : string {
    return this.groupType.reference;
  }

  get displayName(): string {
    return this.groupType.displayName;
  }

  get description() : string[] {
    return this.groupType.description;
  }

  get cadence() {
    return this.groupType.cadence;
  }

  get location() {
    return this.groupType.location;
  }

  get costs() {
    return this.groupType.costs;
  }

  get isClosed() {
    return !!this.groupType.closedReason;
  }

  get closedReason() {
    return this.groupType.closedReason || "";
  }
}