import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Activity, ActivityServiceService } from '../activity-service.service';
import { Contact, ContactServiceService } from '../contact-service.service';
import { Group, GroupServiceService } from '../group-service.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.less']
})
export class GroupComponent implements OnInit {

  private group: Group;
  private activities: Activity[];

  constructor(
    private groupService: GroupServiceService,
    private activityService: ActivityServiceService,
    private contactService: ContactServiceService,
    private route: ActivatedRoute
    ) {
      this.activities = [];
     }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      let groupName = params.get('group');
      this.group = await this.groupService.getGroup(groupName);
      this.activities = await this.activityService.getActivities(groupName);
    })
  }

}
