import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivityServiceService {

  private data: Promise<ActivityType[]>;

  constructor(private http: HttpClient) {
    this.data = new Promise((resolve, reject) => {
      this.http.get('assets/activities.json?t' + new Date().getTime())
        .subscribe((data: ActivityType[]) => resolve(data));
    });
  }


  async getActivities(group: string): Promise<Activity[]> {
    
    let activities = (await this.data)
      .map(x => new Activity(x))
      .filter(x => x.group == group)
      .filter(x => x.till > new Date())
      .sort((a, b) => a.compare(b));

    for (let i = 0; i < activities.length; i++) {
      if (new Date() < activities[i].till && !activities[i].isCancelled) {
        activities[i].isNext = true;
        break;
      }
    }

    return Promise.resolve(activities);
  }

  async getNextActivity(group: string): Promise<Activity> {
    let activities = await this.getActivities(group);

    return activities.find(x => x.isNext);
  }
}

export type ActivityType = {
  date: string,
  duration: number,
  group: string,
  remark?: string,
  cancelled: boolean
};

export class Activity {

  isNext: boolean;

  constructor(private activityType: ActivityType) {
    this.isNext = false;
  }

  get group() {
    return this.activityType.group;
  }

  get isCancelled() {
    return this.activityType.cancelled;
  }

  get from(): Date {
    return new Date(this.activityType.date);
  }

  get till(): Date {
    let date = this.from;
    date.setMinutes(date.getUTCMinutes() + this.activityType.duration * 60);
    return date;
  }

  get remark(): string {
    return this.activityType.remark;
  }

  compare(other: Activity): number {
    return this.from.getUTCSeconds() - other.from.getUTCSeconds();
  }
}