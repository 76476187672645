import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { OrganizationPageComponent } from './organization-page/organization-page.component';
import { GroupComponent } from './group/group.component';


const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'bijeenkomsten/:group', component: GroupComponent },
  { path: 'contact', component: OrganizationPageComponent },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
